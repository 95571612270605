/**
 *
 * WordBee
 *
 */
/* eslint-disable prettier/prettier */
import { Box, Chip, Typography } from '@mui/material';
import { CopyrightTextField } from 'components/CopyrightTextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CustomTextBox } from '../WordBee/components/CustomTextBox';
import { VisibilityOffRounded } from '@mui/icons-material';
import { CustomButtonGroup } from './components/CustomButtonGroup';
import { WordButtons } from './components/WordButtons';
import { BannerText } from './components/BannerText';
import { PageTitle } from './components/PageTitle';


export function WordBee() {

  // Initialize state from session storage or use empty string as default
  const [text, setText] = useState(() => {
    const savedText = sessionStorage.getItem('text');
    return savedText !== null ? savedText : '';
  });

  // Update session storage whenever the text state changes
  useEffect(() => {
    sessionStorage.setItem('text', text);
  }, [text]);


  const [filename, setFilename] = useState('');
  const [wordList, setWordList] = useState([] as string[]);
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(false);
  const [showList, setShowList] = useState(true)

  useEffect(() => {
    if (showMoreContent) {
      // Use a timeout to ensure the DOM updates have been flushed
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 0);
    }
  }, [showMoreContent]);

  useEffect(() => { }, [text, filename]);

  const handleConvertToList = () => {
    // Split the text by line breaks to get the list of words
    if (text !== '') {
      const list = text.split(/[\n,]+/).reduce((acc: string[], item) => {
        const trimmedItem = item.trim();
        if (trimmedItem) acc.push(trimmedItem);
        return acc;
      }, []);

      setWordList(list);
      setToggleDisplay(true);
      setShowMoreContent(true);
    } else {
      alert('Add words to read.');
    }
  };

  const handleClear = () => {
    if (wordList.length > 0) {
      setWordList([]);
      setToggleDisplay(false);
      setText('');
      setShowMoreContent(false);
      setFilename('');
    }

  };

  return (
    <>
      <Box
        sx={{
          background: '#FFD700',
          width: '100%',           // Full width of the viewport minus scrollbar
          height: '100vh',         // Full viewport height
          display: 'flex',         // Use flexbox
          flexDirection: 'column', // Arrange items in a column
          alignItems: 'center',    // Center items horizontally
          justifyContent: 'flex-start', // Start items from the top
          overflowY: 'auto',       // Enable vertical scrolling if content exceeds height
          padding: 3,              // Add padding at the top and around content
          pt: 5,                   // Optional: Extra padding at the top if needed
          boxSizing: 'border-box', // Ensures padding doesn’t cause overflow
        }}
      >
        <Helmet>
          <title>Word Bee</title>
          <meta
            name="description"
            content="Word Bee - A helpful tool for ACE Word Building PACEs and homeschool"
          />
        </Helmet>

        <BannerText/>

        <PageTitle/>
        
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          {showList ?
            <>
              <Typography 
              variant='caption'
              sx={{
                color:'#575757',
                fontFamily: 'Space Mono, sans-serif',}}>Begin by typing word(s) or uploading list</Typography>
              <CustomTextBox text={text} setText={setText} />
            </>
            :
            <>
              <VisibilityOffRounded sx={{ fontSize: '100px', color: "#77777737" }} />
              <Typography 
              variant='h5' sx={{ color: "#77777737",
                fontFamily: 'Space Mono, sans-serif',
               }}>Quiz Mode</Typography>
            </>
          }
          <Box height={20} />
          {filename && <Chip sx={{
              fontFamily: 'Space Mono, sans-serif',
          }}
          label={`Words imported from ${filename}`} />}

          <CustomButtonGroup 
          text={text} 
          setText={setText} 
          filename={filename} 
          setFilename={setFilename} 
          handleConvertToList={handleConvertToList} 
          setShowList={setShowList} 
          showList={showList} 
          handleClear={handleClear} 
          wordList={wordList} />

          {toggleDisplay && (
            <WordButtons 
            wordList={wordList} 
            showList={showList} 
            setShowList={setShowList} />
          )}
          <CopyrightTextField />
        </Box>
      </Box>
    </>
  );
}
