import React from 'react';
import { Box, Typography } from '@mui/material';
import beeimg from '../../WordBee/components/wbee.png';

export const PageTitle = () => {
  return (
    <Typography
      variant="h3"
      align="center"
      fontWeight="bold"
      sx={{
        fontFamily: 'Space Mono, sans-serif',
        color: '#575757',
        fontSize: 'clamp(20px, 8vw, 50px)', // Responsive font size

        // overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        display: 'inline-flex', // Ensures image aligns with text properly
        alignItems: 'center', // Center-aligns text and image vertically
        gap: 1,
        mt: 2, // Space between text and image
      }}
    >
      Word Bee
      <Box
        component="img"
        src={beeimg}
        alt="WBee"
        sx={{
          width: '60px', // Image width
          height: '100px', // Image height
          animation: 'flipVertical 0.07s infinite',
          '@keyframes flipVertical': {
            '0%': { transform: 'rotateX(0deg)' },
            '50%': { transform: 'rotateX(45deg)' },
            '100%': { transform: 'rotateX(0deg)' },
          },
        }}
      />
    </Typography>
  );
};
