import React from 'react';
import Button from '@mui/material/Button';
import { Box, Tooltip } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

export const ExportButton = ({ dataArray }: { dataArray: string[] }) => {
  const handleExport = () => {
    // Convert array to string
    const dataString = dataArray.join('\n');

    // Create a blob from the string
    const blob = new Blob([dataString], { type: 'text/plain;charset=utf-8' });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element and initiate the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported-data.txt'; // Name of the downloaded file
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Tooltip title="Download list" placement="top">
        <Button
          disabled={dataArray.length === 0}
          // variant="outlined"
          color="primary"
          onClick={handleExport}
          sx={{
            marginTop: '20px',
            alignItems: 'center',
          }}
        >
          <FileDownload />
        </Button>
      </Tooltip>
    </Box>
  );
};
