/* eslint-disable prettier/prettier */
import { TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

interface CustomTextBoxProps {
    text: string;
    setText: Dispatch<SetStateAction<string>>;
  }

export const CustomTextBox = ({text, setText}: CustomTextBoxProps) => {
    const [lineNumber, setLineNumber] = useState(1);
    const textFieldRef = useRef<HTMLInputElement>(null);
  
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value);
      updateLineNumber();
    };
  
    const handleCaretUpdate = () => {
      updateLineNumber();
    };
  
    const updateLineNumber = () => {
      const input = textFieldRef.current;
      if (input) {
        const cursorPosition = input.selectionStart || 0;
        const textBeforeCursor = input.value.substring(0, cursorPosition);
        const lineCount = textBeforeCursor.split('\n').length;
        setLineNumber(lineCount);
      }
    };
  
    return (
      <>
        <TextField
          inputRef={textFieldRef}
          multiline
          sx={{ 
            width: '90vw', maxWidth: '600px', background: '#fffbe8',
          '& .MuiInputBase-root': {
            fontFamily: 'Space Mono, sans-serif', // Font for input text
          },
          '& .MuiInputLabel-root': {
            fontFamily: 'Space Mono, sans-serif', // Font for the label
          },
          }}
          minRows={4}
          maxRows={10}
          variant="filled"
          label="Separate w/ ENTER or ,"
          value={text}
          onChange={handleTextChange}
          onClick={handleCaretUpdate}
          onKeyUp={handleCaretUpdate}
        />
        {text && <Typography sx={{
          color:'#575757',
          fontFamily: 'Space Mono, sans-serif'}}>Current line: {lineNumber}</Typography>}
      </>
    );
  };
  