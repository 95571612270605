import React from 'react';
import { CopyrightRounded } from '@mui/icons-material';
import { Box, Chip, Tooltip } from '@mui/material';
import Arkware from '../assets/Arkware-bg-A.png';
import { useNavigate } from 'react-router-dom';

export const CopyrightTextField = () => {
  const currentYear = new Date().getFullYear();

  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate('/');
  };

  // const barcodeJesus = [
  //   [1, 1, 2, 2, 1, 2, 1, 2, 1], // J
  //   [2, 1, 1, 1, 1, 2, 1, 2, 2], // E
  //   [1, 2, 1, 1, 2, 1, 1, 1, 2], // S
  //   [2, 2, 1, 1, 1, 1, 2, 1, 1], // U
  //   [1, 2, 1, 1, 2, 1, 1, 1, 2], // S
  // ];

  // const barcodeSaves = [
  //   [1, 2, 1, 1, 2, 1, 1, 1, 2], // S
  //   [2, 2, 1, 1, 1, 2, 2, 1, 1], // A
  //   [1, 2, 1, 2, 1, 2, 1, 1, 1], // V
  //   [2, 1, 1, 1, 1, 2, 1, 2, 2], // E
  //   [1, 2, 1, 1, 2, 1, 1, 1, 2], // S
  // ];

  // const generateArkwareBars = (barcode) => {
  //   return barcode.map((letterBars, letterIndex) => (
  //     <Box
  //       key={`letter-${letterIndex}`}
  //       sx={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         flexShrink: 1,
  //         maxWidth: '100%',
  //       }}
  //     >
  //       {letterBars.map((width, index) => (
  //         <Box
  //           key={`bar-${letterIndex}-${index}`}
  //           sx={{
  //             width: `min(${width * 3}px, 5%)`, // Dynamic width with a minimum size
  //             height: '40px',
  //             background: '#000000',
  //             marginRight: '2px',
  //             minWidth: '3px', // Ensure a visible minimum width
  //           }}
  //         />
  //       ))}
  //     </Box>
  //   ));
  // };

  return (
    <>
      <Box height={20} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          maxWidth: '100%',
          flexShrink: 1,
          mb: 5,
        }}
      >
        <Box height={20} />
        <CopyrightRounded sx={{ color: 'rgba(0, 0, 0, 0.18)' }} />{' '}
        <Chip
          sx={{ fontFamily: 'Space Mono, sans-serif' }}
          label={`${currentYear} Arb Lim | Arkware.app`}
        />
      </Box>
      <Box
        sx={{
          // display: 'flex',
          // flexDirection: 'row',
          alignItems: 'center',
          // justifyContent: 'space-between',
          // width: '100%',
          // overflowX: 'auto', // Enable scrolling if content exceeds viewport
        }}
      >
        {/* Left Barcode */}
        {/* <Box
          sx={{
            display: 'flex',
            height: '40px',
            maxWidth: '45%',
            flexShrink: 1,
            // overflow: 'auto', // Allow horizontal scrolling
          }}
        >
          {generateArkwareBars(barcodeJesus)}
        </Box> */}
        <Tooltip title="Go to Home">
          <Box
            component="img"
            src={Arkware}
            alt="Arkware Logo"
            onClick={handleNavigateHome}
            sx={{
              width: '40px',
              animation: 'rotateSlant 3s infinite linear',
              transformOrigin: 'center',
              cursor: 'pointer',
              '@keyframes rotateSlant': {
                '0%': { transform: 'rotateY(0deg) rotateX(15deg)' },
                '100%': { transform: 'rotateY(360deg) rotateX(15deg)' },
              },
              mx: '16px',
            }}
          />
        </Tooltip>
        {/* Right Barcode */}
        {/* <Box
          sx={{
            display: 'flex',
            height: '40px',
            maxWidth: '45%',
            flexShrink: 1,
            // overflow: 'auto', // Allow horizontal scrolling
          }}
        >
          {generateArkwareBars(barcodeSaves)}
        </Box> */}
      </Box>
    </>
  );
};
