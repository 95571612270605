import { Box, Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { ImportButton } from './ImportButton';
import { Clear, List, Visibility, VisibilityOff } from '@mui/icons-material';
import { ExportButton } from './ExportButton';

interface CustomButtonGroupProps {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  filename: string;
  setFilename: React.Dispatch<React.SetStateAction<string>>;
  handleConvertToList: () => void;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  showList: boolean;
  handleClear: () => void;
  wordList: string[];
}

export const CustomButtonGroup = ({
  text,
  setText,
  filename,
  setFilename,
  handleConvertToList,
  setShowList,
  showList,
  handleClear,
  wordList,
}: CustomButtonGroupProps) => {
  return (
    <Box sx={{ maxWidth: '500px' }}>
      <Grid
        container
        direction={'row'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        spacing={2}
      >
        <Grid item xs={2.4}>
          <ImportButton
            text={text}
            setText={setText}
            filename={filename}
            setFilename={setFilename}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Tooltip title={'Generate word buttons'} placement="top">
              <Button
                disabled={text.length === 0}
                variant="contained"
                color="success"
                onClick={handleConvertToList}
                sx={{ marginTop: '20px', alignItems: 'center' }}
              >
                <List />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Tooltip title={'Hide words (Quiz Mode)'} placement="top">
              <Button
                variant="contained"
                color="info"
                onClick={() => setShowList(!showList)}
                sx={{ marginTop: '20px', alignItems: 'center' }}
              >
                {showList ? <Visibility /> : <VisibilityOff />}
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Tooltip title={'Clear words'} placement="top">
              <Button
                disabled={text.length === 0}
                variant="contained"
                color="error"
                onClick={handleClear}
                sx={{ marginTop: '20px' }}
              >
                <Clear />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <ExportButton dataArray={wordList} />
        </Grid>
      </Grid>
    </Box>
  );
};
