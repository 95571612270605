/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { VolumeUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useWordBeeSlice } from '../slice';
import { selectCurrentReading } from '../slice/selectors';

export const TtsButton = ({ textToSpeak, index, showList, setShowList }) => {
  const [definitionText, setDefinitionText] = useState('');
  const [loading, setLoading] = useState(false); // New loading state
  const [reading, setReading] = useState(false);

  const dispatch = useDispatch();
  const { actions } = useWordBeeSlice();
  const currentReading = useSelector(selectCurrentReading);

  const speakingRef = useRef(false); // Ref to track speech progress

  // Function to fetch the definition of a word from the dictionary API
  const definition = async (word) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`,
      );
      const data = await response.json();

      if (data && data[0] && data[0].meanings.length > 0) {
        let fullText = `Definition of ${word}:\n`;

        data[0].meanings.forEach((meaning) => {
          const partOfSpeech = meaning.partOfSpeech
            ? `As ${meaning.partOfSpeech}. `
            : '';

          // Get the primary definition
          const wordDefinition = meaning.definitions[0].definition || '';

          // Get the example if available
          const exampleSentence = meaning.definitions[0].example
            ? ` Example usage of the word ${word} as ${meaning.partOfSpeech}. ${meaning.definitions[0].example}.`
            : '';

          // Get synonyms if available
          const synonyms =
            meaning.synonyms && meaning.synonyms.length > 0
              ? ` Synonyms for the word ${word} as ${
                  meaning.partOfSpeech
                } would be. ${meaning.synonyms.join(', ')}.`
              : '';

          // Compose text for this meaning
          fullText += `${partOfSpeech} ${word} means ${wordDefinition}.${exampleSentence}${synonyms}\n`;
        });

        setDefinitionText(fullText.trim()); // Update the state with the composed text
        return fullText.trim();
      } else {
        console.log('No definition found.');
        setDefinitionText('');
        return '';
      }
    } catch (error) {
      console.error('Error fetching definition:', error);
      setDefinitionText('Error fetching definition.');
      return 'Error fetching definition.';
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleStop = () => {
    if ('speechSynthesis' in window) {
      speechSynthesis.cancel(); // Stops any ongoing speech
    }
  };

  const handleToggleSpeak = async () => {
    if (reading || speakingRef.current) {
      // Stop current speech
      handleStop();
      speakingRef.current = false;
      setReading(false);
      dispatch(actions.setCurrentReading(null));
    } else {
      // Start new speech
      handleStop(); // Ensure any ongoing speech is canceled
      speakingRef.current = true; // Lock speech progress
      dispatch(actions.setCurrentReading(index));
      if ('speechSynthesis' in window) {
        setReading(true);
        try {
          // Fetch the definition before speaking
          const wordDefinition = await definition(textToSpeak);
          const utterance = new SpeechSynthesisUtterance(
            `Number ${index + 1}. ${textToSpeak}. ${
              wordDefinition
                ? `${wordDefinition}. Number ${index + 1}. ${textToSpeak}.`
                : ''
            }`,
          );

          utterance.onend = () => {
            setReading(false);
            speakingRef.current = false; // Unlock after speech ends
            dispatch(actions.setCurrentReading(null));
          };

          utterance.onerror = () => {
            setReading(false);
            speakingRef.current = false; // Unlock on error
            console.error('Speech synthesis error occurred');
          };

          speechSynthesis.speak(utterance);
        } catch (error) {
          console.error('Error fetching definition or speaking:', error);
          setReading(false);
          speakingRef.current = false; // Unlock on error
          dispatch(actions.setCurrentReading(null));
        }
      } else {
        alert('Your browser does not support the Web Speech API.');
        speakingRef.current = false; // Unlock immediately if unsupported
      }
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      // onClick={handleSpeak}
      onClick={handleToggleSpeak}
      sx={{
        fontFamily: 'Space Mono, sans-serif',
        backgroundColor: currentReading === index ? '#d32f2f' : '#505050', // Light Gray
        color: '#FFFDD0', // Creamy White
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.1)',
          backgroundColor: currentReading === index ? '#d32f2f' : '#606060',
        },
      }}
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        <>
          {index + 1}: Loading...{' '}
          <CircularProgress size={24} sx={{ color: '#FFFDD0' }} />
        </>
      ) : reading ? (
        <>
          &nbsp;
          {index + 1}
          {showList ? `: ${textToSpeak}` : ''}&nbsp;
          {currentReading === index && <VolumeUp sx={{ color: '#FFFDD0' }} />}
        </>
      ) : (
        `${index + 1}${showList ? `: ${textToSpeak}` : ''}`
      )}
    </Button>
  );
};
