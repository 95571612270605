import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { TtsButton } from './TtsButton';

interface WordButtonsProps {
  wordList: string[];
  showList: boolean;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WordButtons = ({
  wordList,
  showList,
  setShowList,
}: WordButtonsProps) => {
  return (
    <Box marginTop={2} sx={{ width: '90vw', maxWidth: '600px' }}>
      <Typography
        variant="h6"
        align="center"
        sx={{
          color: '#575757',
          fontFamily: 'Space Mono, sans-serif',
        }}
      >
        Click buzzon to read #:WORD aloud ({wordList.length} words)
      </Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      ></Box>
      <Grid
        container
        direction="row"
        justifyContent={'center'}
        alignContent={'center'}
      >
        {wordList.map((word, index) => (
          <Grid item padding={1} key={index}>
            <>
              <TtsButton
                textToSpeak={word}
                index={index}
                showList={showList}
                setShowList={setShowList}
              />
              {/* {currentReading === index && (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Stop />}
                  onClick={handleStop}
                  disabled={currentReading === null} // Disable button if not playing
                  sx={{
                    fontFamily: 'Space Mono, sans-serif',
                    pl: 2,
                  }}
                >
                  Stop
                </Button>
              )} */}
            </>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
