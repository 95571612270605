// import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';
import { wordBeeSaga } from './saga';
import { WordBeeState } from './types';

export const initialState: WordBeeState = {
  tempVar: 0,
  currentReading: null,
};

const slice = createSlice({
  name: 'wordBee',
  initialState,
  reducers: {
    // someAction(state, action: PayloadAction<any>) {},
    setCurrentReading(state, action) {
      state.currentReading = action.payload;
    },
  },
});

export const { actions: wordBeeActions } = slice;

export const useWordBeeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: wordBeeSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useWordBeeSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
