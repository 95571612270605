/**
 *
 * About
 *
 */
// import * as React from 'react';

// interface Props {}

// export function About(props: Props) {

// const style = {padding: '1rem'}

// return (
//   <div style={style}>
//   This is About.
// </div>
// );

// };

// File: src/pages/About/index.tsx
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Avatar,
  // Tooltip,
  // IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import FounderImage from '../../assets/coffee_table.jpg'; // replace with an actual image of the founder, if available
import Arkware from '../../assets/Arkware-bg.png';
// import { Home } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
import { text } from './text';
import { CopyrightTextField } from 'components/CopyrightTextField';

const AboutContainer = styled(Container)({
  textAlign: 'center',
  paddingTop: '40px',
  paddingBottom: '40px',
  backgroundColor: '#f3f4f6',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const AnimatedText = styled(Typography)({
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  animation: 'fadeIn 2s ease-in',
});

const About = () => {
  // const navigate = useNavigate();

  // const handleNavigateHome = () => {
  //   navigate('/');
  // };
  return (
    <>
      <AboutContainer maxWidth="md">
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            // fontFamily: 'Space Mono, sans-serif',
            color: '#122e68',
            mb: 3,
          }}
        >
          {/* About Arkware.app */}
          {text.about}
        </Typography>

        <AnimatedText
          variant="body1"
          sx={{
            mb: 4,
            // fontFamily: 'Space Mono, sans-serif',
            color: '#436aba',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: '#7a9add',
            },
            whiteSpace: 'pre-line',
          }}
        >
          {text.aboutDesc}
        </AnimatedText>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2,
          }}
        >
          <Avatar
            src={FounderImage}
            alt="Founder"
            sx={{
              width: { xs: 120, md: 150 },
              height: { xs: 120, md: 150 },
              border: '4px solid #aaa57d',
              mb: { xs: 2, md: 0 },
              boxShadow: '0 4px 10px rgba(18, 46, 104, 0.3)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          />
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Space Mono, sans-serif',
                color: '#122e68',
                fontWeight: 'bold',
              }}
            >
              {text.team}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontFamily: 'Space Mono, sans-serif',
                color: '#436aba',
                whiteSpace: 'pre-line',
              }}
            >
              {text.teamDesc}
            </Typography>
          </Box>
        </Box>

        <Paper
          elevation={4}
          sx={{
            mt: 5,
            p: 4,
            borderRadius: '16px',
            // backgroundColor: '#c1d7ff',
            // color: '#122e68',
            backgroundColor: '#122e68',
            color: '#ffffff',
            textAlign: 'center',
            fontFamily: 'Space Mono, sans-serif',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            // boxShadow: '0px 6px 18px rgba(67, 106, 186, 0.4)',
            boxShadow: '0px 6px 18px rgba(18, 46, 104, 0.4)',
          }}
        >
          <Box
            component="img"
            src={Arkware}
            alt="Idea Icon"
            sx={{
              height: '50px',
              width: 'auto',
              maxWidth: '100%',
              animation: 'rotateSlant 3s infinite linear',
              transformOrigin: 'center',
              '@keyframes rotateSlant': {
                '0%': { transform: 'rotateY(0deg) rotateX(15deg)' },
                '100%': { transform: 'rotateY(360deg) rotateX(15deg)' },
              },
            }}
          />

          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              // color: '#122e68',
            }}
          >
            {text.mission}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: '600px',
              color: '#c1d7ff',
              whiteSpace: 'pre-line',
            }}
          >
            {text.missionDesc}
          </Typography>
        </Paper>

        <Grid container spacing={4} sx={{ mt: 5 }}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{
                p: 4,
                borderRadius: '16px',
                // backgroundColor: '#122e68',
                // color: '#ffffff',
                backgroundColor: '#436aba',
                color: '#122e68',
                textAlign: 'center',
                fontFamily: 'Space Mono, sans-serif',
                // boxShadow: '0px 6px 18px rgba(18, 46, 104, 0.4)',
                boxShadow: '0px 6px 18px rgba(67, 106, 186, 0.4)',
                '&:hover': {
                  backgroundColor: '#c5bf92',
                },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ color: '#ffffff' }}
              >
                {text.desc1}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, color: '#ffffff', whiteSpace: 'pre-line' }}
              >
                {text.desc1Text}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{
                p: 4,
                borderRadius: '16px',
                backgroundColor: '#aaa57d',
                color: '#ffffff',
                textAlign: 'center',
                fontFamily: 'Space Mono, sans-serif',
                boxShadow: '0px 6px 18px rgba(67, 106, 186, 0.4)',
                '&:hover': {
                  backgroundColor: '#7a9add',
                },
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                {text.desc2}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, color: '#ffffff', whiteSpace: 'pre-line' }}
              >
                {text.desc2Text}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{
            mt: 5,
            // fontFamily: 'Space Mono, sans-serif',
            color: '#122e68',
            textAlign: 'center',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: '#436aba',
            },
            whiteSpace: 'pre-line',
          }}
        >
          {text.bottomlineText}
        </Typography>

        {/* <Tooltip title="Go to Home">
          <IconButton
            onClick={handleNavigateHome}
            sx={{
              color: '#122e68', // Customize color as needed
              ':hover': {
                color: '#436aba', // Change color on hover
              },
            }}
          >
            <Home />
          </IconButton>
        </Tooltip> */}
        <CopyrightTextField />
      </AboutContainer>
    </>
  );
};

export default About;
