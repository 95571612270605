/* eslint-disable react/prop-types */
import React, { useRef, ChangeEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Box, Tooltip } from '@mui/material';
import { FileUpload } from '@mui/icons-material';

export const ImportButton = ({ text, setText, filename, setFilename }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFilename(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result as string;

        setText(contents);
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // UseEffect hook to log the updated wordList
  useEffect(() => {
    console.log(text);
  }, [text, filename]);

  return (
    <>
      <input
        type="file"
        accept=".txt, .csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Tooltip title="Upload list" placement="top">
          <Button
            // variant="outlined"
            onClick={handleButtonClick}
            sx={{
              marginTop: '20px',
              alignItems: 'center',
              color: 'primary',
              // borderColor: '#808080',
            }}
          >
            <FileUpload />
          </Button>
        </Tooltip>
      </Box>
    </>
  );
};
