import { Box, Typography } from '@mui/material';
import React from 'react';

export const BannerText = () => {
  return (
    <Box>
      <Typography
        variant="caption"
        align="center"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          color: '#575757',
          fontSize: 'clamp(5px, 8vw, 12px)',
          fontFamily: 'Space Mono, sans-serif',
          overflow: 'hidden',
          textAlign: 'center',
          mx: 2, // Adds spacing on both sides

          background: 'rgba(0, 0, 0, 0.08)',
          p: 2,
        }}
      >
        An essential tool for ACE Word Building PACEs and homeschooling.
      </Typography>
    </Box>
  );
};
