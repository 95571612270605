export const text = {
  about: 'About Arkware.app',
  aboutDesc: `Welcome to Arkware.app, where ideas meet code with a purpose.\nWe're all about turning the tangled and tiresome into the simple and seamless.`,
  team: 'Meet Our Team',
  teamDesc: `Skill meets heart, and curiosity sparks innovation.\nOur passionate problem-solvers blend logic with compassion and creativity with precision to craft tools that make life easier.
    Guided by collaboration and inspired by your needs, we transform ideas into practical, reliable, and intuitive apps.`,
  mission: 'Our Mission',
  missionDesc: `At Arkware.app, we're driven by a simple goal: We're here to design practical tools that work with you. Our mission is to design apps that make your day smoother, smarter, and more enjoyable.`,
  desc1: 'Smart Apps for Everyone',
  desc1Text: `We make apps that feel smart and intuitive. We craft tools that anticipate your needs, because we believe that technology should be a helping hand.`,
  desc2: `Crafting for All Walks of Life`,
  desc2Text: `Whether you're an entrepreneur, a parent, or anyone else looking to streamline life, our apps are built with you in mind. Practical, powerful, and easy to use.`,
  bottomlineText: `Share your passion with us! Let's make work a little less work and life more enjoyable.`,
};
